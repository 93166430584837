import React, { useState } from 'react';
// import { Element } from 'react-scroll';
// import VisibilitySensor from "react-visibility-sensor";

import Header from '../components/Header/Header';
// import HeroTheLegend from '../components/HeroTheLegend/HeroTheLegend';
// import Reserve from '../components/Reserve/Reserve';
import StoreLocator from '../components/StoreLocator/StoreLocator';
import Subscribe from '../components/Subscribe/Subscribe';
import Instagram from '../components/Instagram/Instagram';
import Footer from '../components/Footer/Footer';
import SEO from '../components/SEO/SEO';
import CookieDisclaimer from '../components/CookieDisclaimer/CookieDisclaimer';

const IndexPage = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = isVisible => setIsVisible(isVisible);

  return (
    <>
      <SEO pageName="locator" />
      <div
        style={{ display: 'flex', flexDirection: 'column', minHeight: '100%' }}
      >
        <Header productIsActive={isVisible} />
        <main id="main" tabIndex="-1">
          <StoreLocator style={{ marginTop: '67px' }} />
          <Subscribe />
          <Instagram />
        </main>
        <Footer />
      </div>
      <CookieDisclaimer />
    </>
  );
};

export default IndexPage;
